// feature photo and main content.


export function Home () {
    return(
        <>
        <div className="home-container"> 
            <h1 id="content-caption">We Repair all Makes and Models</h1>
        
        </div>
        </>
    )
}